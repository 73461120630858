body {
  margin: 0;
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--floral-white-color);
}

:root {
  /* COLORS */
  --primary-color: #5bb030;
  --leaf-color: #5bb030;
  --dark-green-color: #3a7d44;
  --secondary-color: #091026;
  --dark-gray-color: #4c4e52;
  --medium-gray-color: #a9a9a9;
  --light-gray-color: #ebf0f3;
  --floral-white-color: #faf9f6;

  /* SPACING */
  --size-1x: 5px;
  --size-2x: 10px;
  --size-3x: 15px;
  --size-4x: 20px;
  --size-5x: 25px;
  --size-6x: 30px;
  --size-10x: 50px;

  --splitter-size-1x: 0.5px;
  --splitter-size-2x: 1px;
}

.asEnd {
  align-self: end;
}

.jsRight {
  justify-self: right;
}

.smText {
  font-size: small;
}

.xsText {
  font-size: x-small;
}

.lgText {
  font-size: large;
}

.xlText {
  font-size: x-large;
}

.px55Text {
  font-size: 55px !important;
}

.px45Text {
  font-size: 45px !important;
}

.px65Text {
  font-size: 65px !important;
}

.dgText {
  color: var(--dark-gray-color);
}

.prText {
  color: var(--primary-color) !important;
}

.ucText {
  text-transform: uppercase;
}

.lGray {
  color: var(--light-gray-color);
}

.p .mGray {
  color: var(--medium-gray-color);
}

.mTop1 {
  margin-top: var(--size-2x);
}

.mBottom1 {
  margin-bottom: var(--size-2x);
}

.mLeft1 {
  margin-left: var(--size-2x);
}

.mRight1 {
  margin-right: var(--size-2x);
}

.padH1 {
  padding: 0 var(--size-2x) 0 var(--size-2x);
}

.ml6 {
  margin-left: var(--size-6x) !important;
}

.mt2 {
  margin-top: var(--size-2x) !important;
}

.mb1 {
  margin-bottom: var(--size-1x) !important;
}

.mb6 {
  margin-bottom: var(--size-6x) !important;
}

.pt6 {
  padding-top: var(--size-6x) !important;
}

.bold {
  font-weight: 600;
}

.clickable {
  cursor: pointer;
}

.scrollable {
  overflow: scroll;
}

.scrollable::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari and Opera */
}

.header {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;

  height: 100px;
  width: 100%;
  padding: var(--size-2x) var(--size-3x) var(--size-1x) var(--size-3x);
  background-color: var(--primary-color);
  color: var(--light-gray-color);
}

.doughnut {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border: 10px solid;
  border-color: var(--primary-color);
  border-radius: 50%;
}

.doughnut::before {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.statusBar {
  color: var(--light-gray-color);
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.flexColumn {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.customerDetails {
  position: relative;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--size-2x) var(--size-3x) var(--size-2x) var(--size-3x);
}

.logo {
  max-height: 60px;
  max-width: 60%;
}

.mobileView {
  position: relative;
  background-color: white;
  margin: auto;
  max-width: 45vh;
  border-radius: 35px;
  display: grid;
  height: 87vh;
  grid-template-rows: auto auto minmax(0, 1fr) auto;
  overflow: hidden;
  border: 8px solid black;
}

.mainView {
  position: relative;
  background-color: white;
  margin: auto;
  display: grid;
  height: 100vh;
  grid-template-rows: auto auto minmax(0, 1fr) auto;
  overflow: hidden;
}

.mobileClock {
  text-align: center;
  width: 30%;
}

.mobileCamera {
  display: flex;
  align-items: center;
  width: 40%;
  height: 25px;
  border-radius: 0 0 15px 15px;
  background-color: black;
}

.mobileCameraDot {
  border-radius: 50%;
  margin-left: 10%;
  width: 7px;
  height: 7px;
  background-color: var(--medium-gray-color);
  z-index: 1;
}

.mobileIcons {
  width: 30%;
}

.title1 {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-2x) var(--size-3x) var(--size-1x) var(--size-3x);
  color: var(--floral-white-color);
  background-color: var(--primary-color);
  border-bottom: var(--splitter-size-2x) solid var(--light-gray-color);
}

.title2 {
  padding: 0 var(--size-3x) var(--size-1x) var(--size-3x);
  color: var(--dark-gray-color);
  border-bottom: var(--splitter-size-2x) solid var(--light-gray-color);
  display: flex;
  justify-content: space-between;
}

.title3 {
  padding: var(--size-1x) var(--size-3x) var(--size-1x) var(--size-3x);
  color: var(--dark-gray-color);
}

#bottomNavBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--light-gray-color);
  padding: var(--size-2x);
}

.roundedIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.transaction {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: var(--size-3x);
  grid-template-areas:
    'icon description amount'
    'icon date estimate';
  align-items: center;
  border: var(--splitter-size-1x) solid var(--light-gray-color);
  padding: var(--size-1x) var(--size-3x) var(--size-1x) var(--size-3x);
  cursor: pointer;
}

.transaction .icon {
  grid-area: icon;
}

.transaction .icon img {
  width: 100%;
  height: auto;
}

.transaction .description {
  grid-area: description;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.transaction .date {
  grid-area: date;
  color: var(--medium-gray-color);
}

.transaction .amount {
  grid-area: amount;
  align-self: end;
  color: var(--dark-gray-color);
  justify-self: right;
}

.transaction .estimate {
  grid-area: estimate;
  color: var(--primary-color);
  justify-self: right;
}

.box {
  border: var(--splitter-size-2x) solid var(--primary-color);
  border-radius: var(--size-2x);
  margin: var(--size-2x);
  padding: var(--size-3x);
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 2.5px;
}

button.amplify-button--primary {
  background-color: var(--primary-color);
}

.primaryButton {
  background-color: var(--primary-color);
  min-width: 7em;
  color: white;
  border-radius: var(--size-3x);
  padding: var(--size-2x);
  border: none;
  cursor: pointer;
}

.dashboardButton {
  background-color: var(--primary-color);
  min-width: 7em;
  color: white;
  border-radius: var(--size-3x);
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.margin-right-130 {
  margin-right: 130px;
}

.secondaryButton {
  border: var(--splitter-size-2x) solid var(--primary-color);
  min-width: 7em;
  color: var(--primary-color);
  border-radius: var(--size-3x);
  padding: var(--size-2x);
  margin: var(--size-2x);
  text-align: center;
  cursor: pointer;
}

.secondaryButton:hover {
  border: none;
  color: white;
  background-color: var(--primary-color);
}

.pillButton {
  border: var(--splitter-size-2x) solid var(--primary-color);
  min-width: 7em;
  color: var(--primary-color);
  border-radius: var(--size-3x);
  margin-bottom: var(--size-2x);
  text-align: center;
  cursor: pointer;
}

.pillButton:hover {
  border: none;
  color: white;
  background-color: var(--primary-color);
}

.squareButton {
  border: var(--splitter-size-2x) solid var(--primary-color);
  min-width: 7em;
  color: var(--primary-color);
  margin-bottom: var(--size-2x);
  text-align: center;
  cursor: pointer;
}

.squareButton:hover {
  border: none;
  color: white;
  background-color: var(--primary-color);
}

.transactionModal {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  height: 100%;
  width: 100%;
}

.transactionModalContent {
  align-items: center;
  text-align: center;
  background-color: white;
  margin: 10% auto;
  padding: var(--size-3x) var(--size-3x) var(--size-6x) var(--size-3x);
  border: var(--splitter-size-2x) solid var(--light-gray-color);
  width: 95%;
}

.closeButton {
  align-self: end;
  font-size: 30px;
  margin-top: -5%;
  font-weight: highlight;
  color: var(--dark-gray-color);
  cursor: pointer;
}

.detailIcon {
  grid-area: icon;
  max-width: 30%;
  border-radius: var(--size-2x);
  overflow: hidden;
  display: flex;
  align-items: center;
}

.detailIcon img {
  width: 100%;
  height: auto;
}

.detailImpact {
  align-self: stretch;
  border-top: var(--splitter-size-2x) solid var(--light-gray-color);
  margin-top: var(--size-2x);
  padding: var(--size-2x);
  color: var(--primary-color);
}

.breakdownTab {
  cursor: pointer;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border: 2px solid var(--primary-color);
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 16px;
}

.breakdownTab:hover {
  background-color: var(--primary-color);
}

.tabSelected {
  color: white;
  font-weight: 800;
  border-bottom: 2px solid var(--primary-color);
  background-color: var(--primary-color);
}

.breakdownRow {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: var(--size-3x);
  grid-template-areas:
    'icon name fraction'
    'icon count emissions';
  align-items: center;
  border: var(--splitter-size-1x) solid var(--light-gray-color);
  padding: var(--size-1x) var(--size-3x) var(--size-1x) var(--size-3x);
  cursor: pointer;
}

.breakdownRow .icon {
  grid-area: icon;
}

.breakdownRow .icon img {
  width: 100%;
  height: auto;
}

.breakdownRow .name {
  grid-area: name;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakdownRow .count {
  grid-area: count;
  color: var(--dark-gray-color);
}

.breakdownRow .fraction {
  grid-area: fraction;
  align-self: end;
  color: var(--dark-gray-color);
  justify-self: right;
}

.breakdownRow .emissions {
  grid-area: emissions;
  justify-self: right;
}

.emissions {
  color: var(--leaf-color);
}

.chartContainer {
  margin: 0 40px 0 40px;
  position: relative;
}

#breakdownChart {
  position: relative;
  z-index: 1;
}

.middleLabel {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
}

.settingsInput {
  display: block;
  width: 100%;
  border-radius: var(--size-2x);
  border: 1.5px solid var(--primary-color) !important;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.dateNavigationContainer {
  padding: var(--size-2x);
  color: var(--dark-gray-color);
  gap: var(--size-2x);
}

.granularitySelector {
  width: 40%;
  background-color: var(--light-gray-color);
  border: none !important;
  margin: 0 !important;
  padding: var(--size-1x);
  border-radius: var(--size-1x);
}

.languageSelector {
  width: 20%;
  background-color: var(--light-gray-color);
  border: none !important;
  margin: 0 !important;
  padding: var(--size-1x);
  border-radius: var(--size-1x);
}

.selectedPeriod {
  text-align: center;
  width: 55%;
}

.offsetOverview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 0px;
}

.offsetOverviewItem {
  text-align: center;
  width: 33%;
}

.blockButton {
  width: 100%;
  text-align: center;
  margin: auto;
  padding: var(--size-1x);
  color: white;
  background-color: var(--primary-color);
  border-radius: var(--size-2x);
}

.offsetItem {
  display: grid;
  grid-template-columns: 60px 1fr auto auto;
  grid-template-areas:
    'amount project project state'
    'amountUnits price date viewCert';
  column-gap: var(--size-2x);
  align-items: center;
  border: var(--splitter-size-1x) solid var(--light-gray-color);
  padding: var(--size-1x) var(--size-3x) var(--size-1x) var(--size-3x);
}

.offsetItem .state {
  grid-area: state;
  text-transform: capitalize;
  text-align: center;
  background-color: var(--light-gray-color);
  padding: var(--size-1x);
  border-radius: var(--size-1x);
  opacity: 50%;
}

.offsetItem .viewCert {
  grid-area: viewCert;
  color: var(--primary-color);
  text-align: center;
  cursor: pointer;
}

.offsetItem .project {
  grid-area: project;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.offsetItem .amount {
  grid-area: amount;
  text-align: end;
}

.offsetItem .amountUnits {
  grid-area: amountUnits;
  text-align: end;
}

.offsetItem .price {
  grid-area: price;
}

.offsetItem .date {
  grid-area: date;
}

.connectReport {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.notification {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.notification-container {
  border-radius: 20px;
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  max-width: 70vh;
}

.notification-content {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.notification-title {
  display: flex;
  justify-content: space-between;
}

.notification-company {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sectionTitle {
  padding-top: var(--size-6x);
  padding-left: var(--size-6x);
  font-size: 45px;
  font-weight: 900;
}

.sectionSubTitle {
  padding-left: var(--size-6x);
  font-size: 35px;
  font-weight: 700;
  margin-top: var(--size-2x) !important;
  margin-bottom: var(--size-1x) !important;
}

.sectionResult {
  font-size: 35px;
  font-weight: 700;
}

.sectionSubResult {
  font-size: 25px;
  font-weight: 400;
}

.portfolioReportSection {
  margin-top: -40px;
  background-color: #ebf0f3;
  padding-bottom: var(--size-6x);
  padding-left: var(--size-10x);
}

.dashboardSection {
  padding-bottom: var(--size-6x);
  padding-left: var(--size-10x);
}

.dashboardSectionFinancedEmissions {
  height: 500px;
  padding-left: var(--size-10x);
}

.dashboardSectionPortfolioPCAF {
  height: 500px;
  padding-left: var(--size-10x);
}

.separator {
  background-color: #ebf0f3;
  padding-bottom: var(--size-2x);
}

.portfolioReportSectionDashboard {
  margin-left: var(--size-6x);
  margin-right: var(--size-6x);
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(15, auto);
  justify-content: space-between;
  text-align: center;
}

.totalEmissionsSectionDashboard {
  margin-left: var(--size-10x);
  margin-right: var(--size-6x);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(15, auto);
  justify-content: space-between;
  text-align: center;
}

.tile {
  background: #efefef;
  padding: 40px;
  border-radius: 10px !important;
  margin-right: 20px;
}

.tileTotalEmissions {
  background: #efefef;
  padding: 40px;
  border-radius: 10px !important;
  margin-right: 20px;
  height: 360px;
  padding-top: 0px;
}

.financedEmissionsSectionDashboard {
  margin-left: var(--size-6x);
  margin-right: var(--size-6x);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(15, auto);
  justify-content: space-between;
  text-align: center;
}

.threeScopesDiv {
  margin-left: var(--size-6x);
  margin-right: var(--size-6x);
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: repeat(15, auto);
  justify-content: space-between;
  text-align: center;
}

.progressFinancedEmissionsDiv {
  margin: auto;
  width: 500px;
  padding: 10px;
}

.progressPortfolioPcafDiv {
  margin: auto;
  width: 500px;
}

.progressNumberOfSmeDiv {
  margin: auto;
  width: 250px;
  padding: 10px;
}

.pcafImg {
  width: 50%;
  padding-bottom: var(--size-6x);
}

.pcafImgChart {
  width: 785px;
}

.portfolioWeightedSectionDashboard {
  margin-left: var(--size-6x);
  margin-right: var(--size-6x);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(15, auto);
  justify-content: space-between;
  text-align: center;
  background: #efefef;
  padding: 40px;
  border-radius: 10px !important;
  margin-right: 20px;
}

.portfolioPcafSectionDashboard {
  margin-left: var(--size-6x);
  margin-right: var(--size-6x);
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: repeat(15, auto);
  justify-content: space-between;
  text-align: center;
}

.numberOfSmeSectionDashboard {
  margin-left: var(--size-6x);
  margin-right: var(--size-6x);
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: repeat(15, auto);
  justify-content: space-between;
  text-align: center;
}

.twoProgressBars {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(15, auto);
  justify-content: space-between;
  text-align: center;
}

.divider {
  height: 3px;
  background-color: #ebf0f3;
  margin: 0 var(--size-10x) 0 var(--size-10x);
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.logoImg {
  max-width: 100%;
  height: auto;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.footprintStats {
  margin: 20px 40px 40px 40px;
}

.footprintStat {
  margin-bottom: 15px;
}

.co2Number {
  color: var(--primary-color);
}

.arrowButton {
  min-width: 3em;
  color: var(--primary-color);
  text-align: center;
  cursor: pointer;
}

.monthSelectContainer {
}

.userTypeContainer {
  padding: 0 var(--size-2x) 0 var(--size-2x);
}

.noDataText {
  text-align: center;
  margin: 20px 40px 40px 40px;
}

#reportIframeReport {
  z-index: 0;
}

.poweredByReport {
  height: 15px;
  margin-top: 5px;
  margin-left: 10px;
}

.logoReport,
.poweredByReport {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.logoReport {
  height: 30px;
  padding-left: 10px;
}

.logoReport,
.poweredByReport {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.logoContainerReport {
  color: #0000;
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 48px;
  height: var(--size-7x);
  justify-content: center;
}

.headerReport {
  position: fixed;
  top: 0;
  z-index: 100 !important;
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  padding: 40px 12px;
  height: 48px !important;
  width: 100%;
}

.dropdownContainerReport {
  margin-right: 20px;
}

.usecaseDropdownReport {
  padding: 12px;
  border-radius: var(--size-3x);
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  font-size: 12px;
  float: right;
  position: fixed;
  right: 20px;
  top: 15px;
}

.logoAdminView {
  max-height: 50px;
  max-width: 60%;
}

.carousel {
  position: absolute;
  z-index: 2;
  padding-top: 60px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.carouselContainer {
  border-radius: var(--size-2x);
  background-color: #fefefe;
  margin: var(--size-3x) var(--size-4x) var(--size-3x) var(--size-4x);
  border: 2px solid var(--primary-color);
  height: 80%;
}

.carouselContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.carouselTopContent {
  background-color: var(--light-gray-color);
  border-radius: 10px 10px 0px 0px;
}

.exitButton {
  display: flex;
  justify-content: flex-end;
  padding: var(--size-2x) var(--size-2x) var(--size-1x) var(--size-2x);
}

.exitButtonText {
  cursor: pointer;
}

.carouselImage {
  text-align: center;
}

.carouselTitle {
  color: var(--primary-color);
  font-weight: bold;
  padding: var(--size-2x) var(--size-2x) var(--size-2x) var(--size-2x);
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  font-size: var(--size-3x);
}

.carouselDescription {
  padding: var(--size-4x) var(--size-4x) 0 var(--size-4x);
  text-align: center;
  height: 80%;
}

.carouselText {
  margin: 2%;
}

.carouselNavigation {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: var(--size-2x);
}

.carouselNavigationButton {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  display: inline-block;
  cursor: pointer;
}

.selectedNavButton {
  background-color: var(--primary-color);
  color: var(--floral-white-color);
}

.previousButton {
  height: 25px;
  width: 25px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.nextButton {
  height: 25px;
  width: 25px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.hideButton {
  visibility: hidden;
}

.moreInfoButton {
  border: var(--splitter-size-2x) solid var(--primary-color);
  min-width: 7em;
  color: var(--primary-color);
  border-radius: var(--size-3x);
  padding: var(--size-2x);
  margin: var(--size-2x);
  text-align: center;
  cursor: pointer;
}

.moreInfoButton:hover {
  border: none;
  color: white;
  background-color: var(--primary-color);
}

.infoIcon {
  color: var(--primary-color);
  width: 50px;
  height: 50px;
}

.fillSvg {
  fill: var(--primary-color);
}
